
























































































































































































































































































.main_roles_wrapper_container {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
